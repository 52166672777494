import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.string.replace-all.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { creditCardIcon, layersIcon, labIllustration, bookGradient, cardGradient, partialBookGradient, doctorDashboardIllustrator, eyeIcon } from "@debionetwork/ui-icons";
import { queryDnaSamples } from "@debionetwork/polkadot-provider";
import { mapState } from "vuex";
import { ORDER_STATUS_DETAIL, PAYMENT_STATUS_DETAIL } from "@/common/constants/status";
import { getOrderList } from "@/common/lib/api";
import { fmtReferenceFromHex } from "@/common/lib/string-format";
export default {
  name: "CustomerHome",
  data: () => ({
    creditCardIcon,
    layersIcon,
    labIllustration,
    doctorDashboardIllustrator,
    bookGradient,
    cardGradient,
    partialBookGradient,
    eyeIcon,
    cardBlock: false,
    orderList: [],
    testList: [],
    paymentHistory: [],
    tabs: 0,
    headers: [{
      text: "Order ID",
      value: "formated_id",
      sortable: true
    }, {
      text: "Service Name",
      value: "service_info.name",
      sortable: true
    }, {
      text: "Service Provider",
      value: "provider",
      sortable: true
    }, {
      text: "Date",
      value: "created_at",
      sortable: true
    }, {
      text: "Status",
      value: "status",
      sortable: true
    }, {
      text: "Actions",
      value: "actions",
      sortable: false,
      align: "center",
      width: "5%"
    }],
    isLoadingPayments: false,
    isLoadingTest: false,
    showPopupInfo: false
  }),
  computed: {
    ...mapState({
      api: state => state.substrate.api
    })
  },
  mounted() {
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 959) this.cardBlock = true;else this.cardBlock = false;
    });
  },
  async created() {
    if (this.$route.query.currentTab === "recent order") this.tabs = 0;
    if (this.$route.query.currentTab === "recent payments") this.tabs = 1;
    this.showPopupInfo = this.$route.params.openInfo === "open";
    await this.fetchOrders();
    await this.fetchRecentTest();
    await this.getDataPaymentHistory();
  },
  methods: {
    formatDate(date) {
      const formattedDate = new Date(parseInt(date.replace(/,/g, ""))).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric"
      });
      return formattedDate;
    },
    setServiceImage(image) {
      return image ? image : require("@/assets/debio-logo.png");
    },
    setTestStatus(status) {
      if (status === "Rejected") {
        const detail = ORDER_STATUS_DETAIL[status.toUpperCase()];
        return detail().name;
      }
      return ORDER_STATUS_DETAIL[status.toUpperCase()].name;
    },
    setStatusColor(status) {
      if (status === "Rejected") {
        const detail = ORDER_STATUS_DETAIL[status.toUpperCase()];
        return detail().color;
      }
      return ORDER_STATUS_DETAIL[status.toUpperCase()].color;
    },
    setPaymentStatusColor(status) {
      return PAYMENT_STATUS_DETAIL[status.toUpperCase()];
    },
    async fetchOrders() {
      this.orderList = await getOrderList();
    },
    async fetchRecentTest() {
      this.testList = [];
      this.isLoadingTest = true;
      const filteredData = this.orderList.orders.data.filter(recentTest => {
        return recentTest._source.status !== "Unpaid" && recentTest._source.status !== "Cancelled";
      });
      try {
        for (const result of filteredData) {
          var _result$_source$lab_i, _result$_source, _result$_source$lab_i2;
          const {
            status
          } = await queryDnaSamples(this.api, result._source.dna_sample_tracking_id);
          const dataDetail = {
            ...result._source,
            id: result._id,
            formated_id: fmtReferenceFromHex(result._id),
            provider: (_result$_source$lab_i = (_result$_source = result._source) === null || _result$_source === void 0 ? void 0 : (_result$_source$lab_i2 = _result$_source.lab_info) === null || _result$_source$lab_i2 === void 0 ? void 0 : _result$_source$lab_i2.name) !== null && _result$_source$lab_i !== void 0 ? _result$_source$lab_i : "Unknown Lab Provider",
            timestamp: parseInt(result._source.created_at.replaceAll(",", "")),
            created_at: new Date(parseInt(result._source.created_at.replaceAll(",", ""))).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric"
            }),
            status
          };
          this.testList.push(dataDetail);
        }
        this.testList.sort((a, b) => parseInt(b.timestamp) - parseInt(a.timestamp));
        this.isLoadingTest = false;
      } catch (error) {
        this.isLoadingTest = false;
      }
    },
    async getDataPaymentHistory() {
      this.paymentHistory = [];
      try {
        this.isLoadingPayments = true;
        const {
          orders,
          ordersGA
        } = this.orderList;
        const results = [...orders.data, ...ordersGA.data];
        for (const result of results) {
          var _result$_source$genet, _result$_source2, _result$_source2$gene, _result$_source$genet2, _result$_source3, _result$_source3$gene, _result$_source$lab_i3, _result$_source4, _result$_source4$lab_;
          const analystName = "\n            ".concat((_result$_source$genet = (_result$_source2 = result._source) === null || _result$_source2 === void 0 ? void 0 : (_result$_source2$gene = _result$_source2.genetic_analyst_info) === null || _result$_source2$gene === void 0 ? void 0 : _result$_source2$gene.first_name) !== null && _result$_source$genet !== void 0 ? _result$_source$genet : "", "\n            ").concat((_result$_source$genet2 = result === null || result === void 0 ? void 0 : (_result$_source3 = result._source) === null || _result$_source3 === void 0 ? void 0 : (_result$_source3$gene = _result$_source3.genetic_analyst_info) === null || _result$_source3$gene === void 0 ? void 0 : _result$_source3$gene.last_name) !== null && _result$_source$genet2 !== void 0 ? _result$_source$genet2 : "", "\n          ");
          const computeAnalystName = !/^\s*$/.test(analystName) ? analystName : "Unknown Analyst Provider";
          const dataDetail = {
            ...result._source,
            type: result._index,
            id: result._id,
            formated_id: fmtReferenceFromHex(result._id),
            provider: result._index === "orders" ? (_result$_source$lab_i3 = (_result$_source4 = result._source) === null || _result$_source4 === void 0 ? void 0 : (_result$_source4$lab_ = _result$_source4.lab_info) === null || _result$_source4$lab_ === void 0 ? void 0 : _result$_source4$lab_.name) !== null && _result$_source$lab_i3 !== void 0 ? _result$_source$lab_i3 : "Unknown Lab Provider" : computeAnalystName,
            timestamp: parseInt(result._source.created_at.replaceAll(",", "")),
            created_at: new Date(parseInt(result._source.created_at.replaceAll(",", ""))).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "short",
              year: "numeric"
            }),
            provider_service_image: result._index === "genetic-analysis-order" ? result._source.genetic_analyst_info.profile_image : result._source.service_info.image,
            tracking_id: result._index === "genetic-analysis-order" ? result._source.genetic_analysis_tracking_id : result._source.dna_sample_tracking_id
          };
          this.paymentHistory.push(dataDetail);
        }
        this.paymentHistory.sort((a, b) => {
          if (b.status === "Unpaid") return;else return parseInt(b.timestamp) - parseInt(a.timestamp);
        });
        this.isLoadingPayments = false;
      } catch (error) {
        this.isLoadingPayments = false;
      }
    },
    goToOrderDetail(_ref) {
      let {
        id
      } = _ref;
      this.$router.push({
        name: "order-history-detail",
        params: {
          id
        }
      }); //go to order history detail page
    },

    goToPaymentDetail(_ref2) {
      let {
        id
      } = _ref2;
      this.$router.push({
        name: "customer-payment-details",
        params: {
          id
        }
      }); //go to payment detail
    }
  }
};